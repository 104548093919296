import docReady from '@/js/helpers/doc-ready'
import gsap from 'gsap'
import { debounce } from '@/js/helpers/throttle'
import AsyncSection from '@/js/helpers/async-section'

const axios = require('axios')
const queryString = require('query-string')
const CustomEvent = require('custom-event')

class AsyncArchive extends AsyncSection {
  constructor (el) {
    super(el)

    this.cptForm = document.getElementById('filter-form')
    this.sortEl = document.getElementById('sort')
    this.amount = document.getElementById('show-amount')
    this.checkboxes = document.querySelectorAll('.activity-filter__checkbox')

    // eslint-disable-next-line
    const queryVars = JSON.parse(qv)

    this.pageIndex = queryVars.paged ? queryVars.paged : 1
    this.postType = queryVars.post_type ? queryVars.post_type : 'any'
    this.postsPerPage = queryVars.posts_per_page ? queryVars.posts_per_page : 12
    this.sort = queryVars.sort ? queryVars.sort : 'popular'
    this.city = queryVars.city ? queryVars.city : ''
    this.duration = queryVars.duration ? queryVars.duration : ''
    this.price = queryVars.price ? queryVars.price : ''

    this.init()
  }

  changeAmount (e) {
    this.postsPerPage = e.target.value
    this.dispatchSubmit()
  }

  changeSort (e) {
    this.sort = e.target.value
    this.dispatchSubmit()
  }

  dispatchSubmit () {
    // eslint-disable-next-line
    const event = new CustomEvent('submit', {
      bubbles: true,
      cancelable: true
    })
    this.cptForm.dispatchEvent(event)
  }

  submitCptForm (e) {
    e.preventDefault()

    // Reset pageindex
    this.pageIndex = 1

    // Get all checked post types / acitivities
    const checkedCustomPostTypes = e.target.querySelectorAll('.activity-filter__checkbox:checked[data-cpt-input]')
    const checkedCustomPostTypesArr = Array.prototype.slice.call(checkedCustomPostTypes)

    this.postType = checkedCustomPostTypes.length ? checkedCustomPostTypesArr.map(item => item.value) : 'any'
    // this.postType = checkedCustomPostTypesArr.map(item => item.value)

    const taxonomies = ['city', 'duration', 'price']
    for (let i = 0; i < taxonomies.length; i++) {
      const tax = taxonomies[i]
      const els = e.target.querySelectorAll(`.activity-filter__checkbox:checked[data-${tax}-input]`)

      if (els.length) {
        const checkedArr = Array.prototype.slice.call(els)
        this[tax] = checkedArr.map(item => item.value)
      } else {
        this[tax] = ''
      }
    }

    // Get new activities matching checked boxes
    this.goToPage()
  }

  init () {
    if (this.cptForm) {
      this.cptForm.addEventListener('submit', this.submitCptForm.bind(this))
    }
    if (this.amount) {
      this.amount.addEventListener('change', this.changeAmount.bind(this))
    }
    if (this.sortEl) {
      this.sortEl.addEventListener('change', this.changeSort.bind(this))
    }
  }

  popGo (e) {
    // eslint-disable-next-line
    window.location.href = window.location.href
  }

  getParams () {
    return {
      action: 'activities_list',
      paged: this.pageIndex,
      post_type: this.postType,
      posts_per_page: this.postsPerPage,
      city: this.city,
      duration: this.duration,
      price: this.price,
      sort: this.sort
    }
  }

  beforeNavigation () {
    gsap.to(this.el, 0.2, {
      opacity: 0.8
    })
  }

  goToPage () {
    this.beforeNavigation()
    this.removeEvents()

    const mainParams = this.getParams()
    const formParams = Object.assign({}, this.getParams(), {
      action: 'activities_form'
    })

    axios
      .all([
        axios.get(ajaxurl, { params: mainParams }),
        axios.get(ajaxurl, { params: formParams })
      ])
      .then(axios.spread((res1, res2) => {
        // Form response
        if (this.cptForm.firstChild) {
          while (this.cptForm.firstChild) {
            this.cptForm.removeChild(this.cptForm.firstChild)
          }
        }

        if (res2.data) {
          this.cptForm.insertAdjacentHTML('beforeEnd', res2.data)
        }

        // Original res at the end
        this.navigationSuccess(res1)
      }))
      .catch(errors => {
        console.log('errors', errors)
        // this.navigationError(error)
      })
  }

  setUrl () {
    const parsedUrl = queryString.parseUrl(window.location.href)
    const args = {}

    if (this.postType) {
      args.post_type = this.postType
    }

    if (this.postsPerPage) {
      args.posts_per_page = this.postsPerPage
    }

    if (this.sort) {
      args.sort = this.sort
    }

    if (this.pageIndex) {
      args.paged = this.pageIndex
    }

    if (this.city) {
      args.city = this.city
    }

    if (this.duration) {
      args.duration = this.duration
    }

    if (this.price) {
      args.price = this.price
    }

    const stringified = queryString.stringify(args, { arrayFormat: 'bracket' })
    const base = parsedUrl.url
    const newUrl = `${base}?${stringified}`

    window.history.pushState('', '', newUrl)
  }

  navigationSuccess (response) {
    gsap.to(this.el, 0.2, {
      opacity: 1
    })

    if (this.pageIndex > 1) {
      window.scrollTo({ top: 0, behavior: 'smooth' })
    }

    const completeFn = () => {
      this.setUrl()

      if (this.el.firstChild) {
        while (this.el.firstChild) {
          this.el.removeChild(this.el.firstChild)
        }
      }

      if (response.data) {
        this.el.insertAdjacentHTML('beforeEnd', response.data)
      }

      if (document.querySelector('.activity-list__item')) {
        gsap.fromTo('.activity-list__item', 0.4, {
          opacity: 0,
          y: 10
        }, {
          opacity: 1,
          y: 0,
          stagger: 0.05
        })
      }

      this.initEvents()
    }

    if (document.querySelector('.activity-list__item')) {
      gsap.to('.activity-list__item', 0.2, {
        opacity: 0,
        y: 10,
        stagger: 0.05,
        onComplete: completeFn
      })
    } else {
      completeFn()
    }
  }

  removeEvents () {
    super.removeEvents()

    if (this.checkboxes.length) {
      for (let i = 0; i < this.checkboxes.length; i++) {
        this.checkboxes[i].removeEventListener('change', this.debouncedDispatchSubmit)
      }
    }

    this.debouncedDispatchSubmit = null
  }

  initEvents () {
    super.initEvents()

    this.checkboxes = document.querySelectorAll('.activity-filter__checkbox')

    this.debouncedDispatchSubmit = debounce(this.dispatchSubmit.bind(this), 1000)

    if (this.checkboxes.length) {
      for (let i = 0; i < this.checkboxes.length; i++) {
        this.checkboxes[i].addEventListener('change', this.debouncedDispatchSubmit.bind(this))
      }
    }
  }
}

docReady(() => {
  const activitiesEl = document.getElementById('ajax-activities')
  if (activitiesEl) {
    // eslint-disable-next-line
    const asyncBlog = new AsyncArchive(activitiesEl)
  }
})
