import createSlider from '@/js/helpers/create-slider'
import docReady from '@/js/helpers/doc-ready'

docReady(() => {
  const uspSliders = document.querySelectorAll('.usp-slider')

  const uspSlidersParams = {
    percentPosition: true,
    autoPlay: 3500,
    draggable: false
  }

  if (uspSliders) {
    for (let i = 0; i < uspSliders.length; i++) {
      createSlider(uspSliders[i], uspSlidersParams)
    }
  }

  const categoriesSliders = document.querySelectorAll('.categories-slider, .general-sidebar__activities')
  const categoriesSlidersParams = {
    groupCells: true,
    percentPosition: true
  }

  if (categoriesSliders) {
    for (let i = 0; i < categoriesSliders.length; i++) {
      createSlider(categoriesSliders[i], categoriesSlidersParams)
    }
  }

  const cardsSliders = document.querySelectorAll('.l-cards__slider, .l-reviews__slider')
  const cardsSlidersParams = {
    percentPosition: true,
    watchCSS: true
  }

  if (cardsSliders) {
    for (let i = 0; i < cardsSliders.length; i++) {
      createSlider(cardsSliders[i], cardsSlidersParams)
    }
  }

  const largeSlider = document.querySelector('.large-slider')
  const largeSliderParams = {
    percentPosition: true,
    freeScroll: false,
    wrapAround: true,
    contain: false
  }

  if (largeSlider) {
    createSlider(largeSlider, largeSliderParams)
  }
})
